



























import {
  Component,
  Prop,
  Watch,
  Provide,
  Vue,
  Emit,
} from 'vue-property-decorator'
import {DappSocial} from '@/model/resource/DappSocial'
import {InputDappSocialSchema} from '@/schema/resource/DappSocial/InputDappSocialSchema'
import {Dapp} from '@/model/resource/Dapp'

@Component
export default class PersistDappSocialView extends Vue {
  @Prop() id1?: string
  @Prop() id2?: string
  @Prop() dapp!: Dapp

  @Provide('validator') validator = this.$validator

  schema = new InputDappSocialSchema()
  dappSocial = new DappSocial()

  async created() {
    this.dappSocial.dapp = this.dapp
    this.populateResource()
    await this.populate()
  }

  populateResource() {
    this.schema.collectionSocialType.queryAsPage()
  }

  async populate() {
    const id1 = Number(this.id1) || null
    const id2 = Number(this.id2) || null

    if (id1 && id2) {
      await this.dappSocial.getDappSocial(id1, id2)
    }

    this.$await.done('getDappSocial')
  }

  @Emit('persist')
  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    return this.dappSocial
  }
}
